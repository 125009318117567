import MaxWidthWrapper from 'components/wrappers/MaxWidthWrapper';
import React from 'react';
import ShopTheLookBlock from 'libs/wordpress/content/cmsBlocks/ShopTheLookBlock';
import moduleSpacing from 'config/theme/moduleSpacing';

const ShopTheLookModule = ({ ...rest }) => (
    <MaxWidthWrapper includeContentSpacing as="section" py={moduleSpacing.md}>
        <ShopTheLookBlock {...rest} />
    </MaxWidthWrapper>
);

export default ShopTheLookModule;
