import ProductsContainer from 'containers/ProductsContainer';
import PropTypes from 'prop-types';
import React from 'react';
import ShopTheLook from 'components/contentLayouts/ShopTheLook';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import { sortArrayByTargetArray } from 'utils/array';
import transformBackgroundImage from 'libs/wordpress/utils/transformBackgroundImage';
import transformButton from 'libs/wordpress/utils/transformButton';
import useSaveResponse from 'hooks/useSaveResponse';
import { useTranslation } from 'react-i18next';

// Products Quantity 9999 is used because we do not support multiple pages in Shop the Look and that's why we want to get all products at once.
const ShopTheLookBlock = ({ content = {}, productsQuantity = 9999 }) => {
    const { t } = useTranslation();
    const { button, heading, looks } = content;

    // Takes all the id:s from all looks and merges them into one so that we only need one call to algolia
    const allProductsIds = looks.reduce((mergedIds, ids) => {
        ids.products_selection_manual.products.forEach(id => {
            if (!mergedIds.includes(id)) {
                mergedIds.push(parseInt(id, 10));
            }
        });

        return mergedIds;
    }, []);

    // Save response from container
    const [useResponse, saveResponse] = useSaveResponse();

    return (
        <ProductsContainer
            pageSize={productsQuantity}
            ids={allProductsIds}
            render={({ response }) => {
                saveResponse(response);

                // Transform products data
                const responseProducts = useResponse?.current?.products || [];

                // Transform looks data
                const transformedLooks = looks.map(look => {
                    const allProductIds = look.products_selection_manual.products?.map(i => parseInt(i, 10));

                    const landscape = transformBackgroundImage(
                        look.background_portrait_landscape?.landscape?.background_image || {}
                    );

                    const portrait = transformBackgroundImage(
                        look.background_portrait_landscape?.portrait?.background_image || {}
                    );

                    // Sort the products from Centra in the same order as it was from the cms
                    // Filter out correct products from transformed products based och ids froom the cms
                    const products = allProductIds
                        ? sortArrayByTargetArray(
                              allProductIds,
                              responseProducts.filter(product => allProductIds.includes(product.id)),
                              'id'
                          )
                        : [];

                    // Just the ids that are in stock for this look
                    const productIds = products.reduce((ids, product) => {
                        if (product.inStock) {
                            ids.push(product.variationId);
                        }
                        return ids;
                    }, []);

                    return {
                        background: { landscape, portrait },
                        products,
                        productIds,
                    };
                });

                return (
                    <ShopTheLook
                        button={button ? transformButton(button) : null}
                        heading={heading ? decodeHTMLEntities(heading.text) : null}
                        headingTag={heading?.type || null}
                        looks={transformedLooks || []}
                    />
                );
            }}
            t={t}
        />
    );
};

ShopTheLookBlock.propTypes = {
    content: PropTypes.shape({
        button: PropTypes.object,
        heading: PropTypes.object,
        looks: PropTypes.array.isRequired,
    }),
    productsQuantity: PropTypes.number,
};

export default ShopTheLookBlock;
