import { above, media } from 'utils/mediaqueries';
import { borderRadius, hoverRadius } from 'config/theme/borderRadius';

import ArrowLink from 'components/text/ArrowLink';
import BackgroundImage from 'components/background/BackgroundImage';
import BasketIcon from 'assets/icons/Basket';
import Heading from 'components/text/Heading';
import PlusIcon from 'assets/icons/Plus';
import PropTypes from 'prop-types';
import React from 'react';
import ThemeButton from 'components/buttons/ThemeButton';
import contentSpacing from 'config/theme/contentSpacing';
import { headingTagProp } from 'utils/proptypes/modules/textProps';
import ratios from 'config/theme/ratios';
import styleObjectToProps from 'utils/styleObjectToProps';
import styled from 'libs/styled';
import transitions from 'config/theme/transitions';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Div = styled('div')``;

const Grid = styled('ul')`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 40px 8px;
    align-items: start;

    ${above.tabletSm} {
        gap: 48px 16px;
    }
`;

const Item = styled('li')`
    grid-column: span 6;
    cursor: pointer;

    :nth-of-type(4n + 1) {
        grid-column: span 12;
    }

    :nth-of-type(4n + 2) {
        grid-column: 4 / span 9;
    }

    :nth-of-type(4n + 3) {
        grid-column: span 12;

        .background-look {
            margin-right: ${contentSpacing[0]};
            width: calc(100% + ${contentSpacing[0]});
        }
    }

    :nth-of-type(4n + 4) {
        grid-column: span 9;
    }

    ${above.tabletSm} {
        :nth-of-type(4n + 1) {
            grid-column: span 6;
        }

        :nth-of-type(4n + 2) {
            grid-column: 8 / span 5;
        }

        :nth-of-type(4n + 3) {
            grid-column: span 3;
            align-self: end;
        }

        :nth-of-type(4n + 4) {
            grid-column: 6 / span 7;

            .background-look {
                margin-left: -${contentSpacing[2]};
                width: calc(100% + ${contentSpacing[2]});
            }
        }
    }
`;

const ImageWrapper = styled('div')`
    position: relative;
    overflow: hidden;
    transition: border-radius ${transitions.primary};

    ${media.hover} {
        :hover {
            border-radius: ${hoverRadius};
        }
    }
`;

const Badge = styled('div')`
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    gap: 0 16px;
    overflow: hidden;
    padding: 8px 16px;
    background-color: var(--static-background-color-primary);
    border-radius: 36px;
`;

const ShopTheLook = ({ button = {}, heading = '', headingTag = 'h2', looks = [] }) => {
    const { t } = useTranslation();
    const overlay = useSelector(state => state.overlay);
    const isAboveTabletSm = useAboveBreakpoint('tabletSm');
    const hasButton = button.label && button.to;

    const openShopTheLookOverlay = look => {
        overlay.show('shopTheLookOverlay', {
            look,
        });
    };

    const customQuery = [
        { w: 360, q: 70 },
        { w: 768, q: 70 },
        { w: 1024 / 2, q: 70 },
        { w: 1280 / 2, q: 70 },
        { w: 1366 / 2, q: 70 },
        { w: 1536 / 2, q: 70 },
        { w: 1920 / 2, q: 70 },
        { w: 2560 / 2, q: 70 },
    ];

    return (
        <Div position="relative">
            <Div
                alignItems={['flex-start', null, 'flex-end']}
                display="flex"
                flexDirection={['column', null, 'row']}
                justifyContent="space-between"
                marginBottom={['16px', null, '24px']}
            >
                <Heading
                    as={headingTag}
                    fontKeys={['Recife Display/32_120', null, 'Recife Display/40']}
                    width={['100%', null, '75%']}
                >
                    {heading}
                </Heading>
                {hasButton && (
                    <ArrowLink display={['none', null, 'inline-flex']} to={button.to}>
                        {button.label}
                    </ArrowLink>
                )}
            </Div>
            <Grid>
                {looks.map((look, index) => {
                    const products = look.products;
                    const numberOfProducts = products.length;
                    const landscapeLayout = isAboveTabletSm ? index === 3 : index === 2;
                    const transformedBackground = landscapeLayout
                        ? look.background.landscape
                        : look.background.portrait;

                    return (
                        <Item key={index}>
                            <ImageWrapper borderRadius={borderRadius} onClick={() => openShopTheLookOverlay(look)}>
                                {!!numberOfProducts && (
                                    <Badge {...styleObjectToProps('NB International/16_100')}>
                                        <BasketIcon color="currentColor" width="12px" />
                                        {numberOfProducts}
                                    </Badge>
                                )}
                                <BackgroundImage
                                    aspectRatio={landscapeLayout ? ratios.horizontal : ratios.vertical}
                                    className="background-look"
                                    src={transformedBackground.url}
                                    position={transformedBackground.backgroundPosition}
                                    size={transformedBackground.backgroundSize}
                                    query={customQuery}
                                />
                            </ImageWrapper>
                            <ThemeButton
                                fontKeys={['NB International/12_120_7', null, 'NB International/14_100_7']}
                                marginTop="16px"
                                theme="outlined"
                                onClick={() => openShopTheLookOverlay(look)}
                            >
                                {t('utils.see_products')} <PlusIcon color="currentColor" marginLeft="8px" width="9px" />
                            </ThemeButton>
                        </Item>
                    );
                })}
            </Grid>
            {hasButton && (
                <ThemeButton
                    display={['block', null, 'none']}
                    marginTop="48px"
                    theme="solid"
                    to={button.to}
                    width="100%"
                >
                    {button.label}
                </ThemeButton>
            )}
        </Div>
    );
};

ShopTheLook.propTypes = {
    button: PropTypes.object,
    heading: PropTypes.string,
    headingTag: headingTagProp,
    looks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ShopTheLook;
